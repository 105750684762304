<template>
    <div>
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入名称或者邮箱搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="getTableData()"
        />
        <span>
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <span>
            <slot name="left" />
            <el-button
              class="filter-item"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="add()"
            >
              新增
            </el-button>
            <!-- <el-button
              class="filter-item"
              size="mini"
              type="success"
              icon="el-icon-edit"
            >
              修改
            </el-button>
            <el-button
              slot="reference"
              class="filter-item"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            >
              删除
            </el-button> -->
          </span>
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-card>
          <el-table
          :data="tableData"  highlight-current-row
          @current-change="companyCurrentChange"
          border stripe
          style="width: 100%">
          <el-table-column
            fixed
            prop="user_ID"
            label="用户ID"
            width="150">
          </el-table-column>
          <el-table-column
            prop="user_NAME"
            label="用户名"
            width="350">
          </el-table-column>
          <el-table-column
            prop="user_EMAILL"
            label="邮箱"
            width="140">
          </el-table-column>
          <el-table-column
            prop="user_SEX"
            label="性别"
            width="150">
          </el-table-column>
          <el-table-column
            prop="user_TEL"
            label="电话"
            width="150" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
            prop="CREATE_TIME"
            label="创建时间"
            width="150" :show-overflow-tooltip="true">
          </el-table-column>
          <!-- <el-table-column
            prop="user_ROLE"
            label="角色"
            width="150" :show-overflow-tooltip="true">
          </el-table-column> -->
          <el-table-column
            label="操作" align="center"
            width="180">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)"  ></el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[2, 5, 10, 15]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totle"
        ></el-pagination>
        <el-dialog append-to-body  title="用户" :visible.sync="addDialogVisible" width="35%" @close='resetForm'>
          <el-form ref="form" :rules="rules" class="demo-ruleForm" :model="form"  label-width="80px" >
            <div>
            <el-form-item label="用户名" prop="user_NAME" >
              <el-col :span="11">
                <el-input v-model="form.user_NAME"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="密码" prop="password" >
              <el-col :span="11">
                <el-input v-model="form.password"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="邮箱" prop="user_EMAILL" >
              <el-col :span="11">
                <el-input v-model="form.user_EMAILL"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="性别" prop="user_SEX" >
              <el-col :span="11">
                <el-select  v-model="form.user_SEX" placeholder="请选择">
                  <el-option
                    v-for="item in sexOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
               </el-col>
            </el-form-item>
            <el-form-item label="电话" prop="user_TEL" >
              <el-col :span="11">
                <el-input v-model="form.user_TEL"></el-input>
               </el-col>
            </el-form-item>
            <el-form-item label="角色">
              <el-select  v-model="form.user_ROLES" multiple placeholder="请选择">
                <el-option
                  v-for="item in roleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="GICS分配">
              <el-col :span="11">
                <el-cascader
                  v-model="gics_VALUES"
                  :options="options"
                  :props="props"
                  collapse-tags
                  clearable>
                </el-cascader>
              </el-col>
            </el-form-item>
            <!-- <el-form-item label="是否有效" prop="is_VALID">
              <el-select v-model="form.is_VALID"  placeholder="请选择">
                <el-option
                  v-for="item in isOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="resetForm()" >取消</el-button>
            <el-button v-if="isAdd == true"  type="primary" @click="submitFrom('form')">确认</el-button>
            <el-button v-if="isAdd == false" type="primary" @click="updateFrom('form')">修改</el-button>
          </div>
        </el-dialog>
        </el-card>
    </div>
</template>
<script>
export default {
  name: 'User',
  created () { //  初始化
    this.getTableData()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/user', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    companyCurrentChange (val) {
    },
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    add () {
      this.rules.password[0].required = true
      this.queryRoleOptions()
      this.getGics()
      this.addDialogVisible = true
      this.isAdd = true
    },
    async handleEdit (index, row) {
      this.rules.password[0].required = false
      this.queryRoleOptions()
      this.getGics()
      this.addDialogVisible = true
      this.isAdd = false
      const { data: res } = await this.$http.get('query/user/by/id', {
        params: { user_ID: row.user_ID }
      })
      this.form = res
      this.gics_VALUES = res.gics_VALUES
      this.form.password = ''
    },
    resetForm () {
      this.addDialogVisible = false
      this.form = this.cleanForm
      this.gics_VALUES = []
    },
    async submitFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        this.form.gics_VALUES = this.gics_VALUES
        await this.$http.post('insert/user', this.form)
        this.resetForm()
        this.getTableData()
      }
    },
    async updateFrom (formName) {
      var isPass = false
      this.$refs[formName].validate((valid) => {
        if (valid) {
          isPass = true
        }
      })
      if (isPass) {
        this.form.gics_VALUES = this.gics_VALUES
        await this.$http.post('update/user', this.form)
        this.resetForm()
        this.getTableData()
      }
    },
    async deleteFrom (index, row) {
      await this.$http.post('delete/user', row)
      this.getTableData()
    },
    async queryRoleOptions (index, row) {
      const { data: res } = await this.$http.get('query/role/node')
      this.roleOptions = res
    },
    async getGics () {
      const { data: res } = await this.$http.get('gics')
      this.options = res
    }
  },
  data () {
    return {
      form: {
        user_ID: '',
        user_NAME: '',
        user_EMAILL: '',
        user_SEX: '',
        user_ROLE: '',
        password: '',
        user_ROLES: [],
        gics_VALUES: []
      },
      gics_VALUES: [],
      cleanForm: {
        user_ID: '',
        user_NAME: '',
        user_EMAILL: '',
        user_SEX: '',
        user_ROLE: '',
        password: '',
        user_ROLES: [],
        gics_VALUES: []
      },
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10
      },
      totle: 0,
      tableData: [],
      isAdd: '',
      addDialogVisible: false,
      isOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      roleOptions: [],
      sexOptions: [{
        value: '0',
        label: '女'
      }, {
        value: '1',
        label: '男'
      }],
      value1: ['1', '2'],
      rules: {
        user_NAME: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        password: [
          { required: true, message: ' ', trigger: 'blur' }
        ],
        user_SEX: [
          { required: true, message: ' ', trigger: 'blur' }
        ]
      },
      props: { multiple: true },
      options: []
    }
  }
}
</script>
<style >
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
</style>
